<app-header></app-header>
<div class="hero-section section pt-0">
    <div class="container pt-5">
 
    </div>
   
  </div>

  <div class="about-section section container">
    <div class="row">
      <div class="col-12 col-lg-12 mb-3 mb-lg-0">
        <p class="display-5 fw-normal">Coast Bus</p>
        <p style="text-align: justify;">
          We have buses to suit your every need. If you are looking for reasonable, affordable and safe means to travel then Coast Bus is the answer. If you are a little more fussy and want First Class service we have the right bus for you. Our buses come with Airconditioning, Free WiFi interent, Snacks & Washroom onboard and hostesses to serve you.
          Social Media You Can get regular updates from our social media sites. Coast Bus is the most well known bus operator in East Africa. With over 56 years of experience we provide the safest, most comfortable luxury buses on Kenyan roads today.
      </div>
    </div>
  </div>
  <app-footer></app-footer>
  